<template>
  <div class="flex w-auto mt-2 md:items-center md:justify-end md:mt-0">
    <t-dropdown variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          :class="getButtonClass"
          aria-label="Report Status Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <loading
            :height="25"
            :opacity="0"
            color="#fff"
            v-if="isLoading"
            :active="true"
            loader="bars"
            :is-full-page="false"
          />
          <span v-if="!isLoading">
            <i class="mr-2 fas fa-chevron-down"></i>
            {{ getStatusText }}
          </span>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-md">
          <t-dropdown-item class="font-medium" @click="executeAction('status')">
            {{ getLockUnlockCommand ? 'Close' : 'Open' }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="isShowEdit"
            class="font-medium"
            @click="executeAction('edit')"
          >
            Edit
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import { SunpodsConfig } from '@/config/SunpodsConfig'
export default {
  name: 'MoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },
  props: {
    sunpodId: {
      type: String,
      default: '',
      required: true,
    },
    isDoorOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
    isShowEdit: {
      type: Boolean,
      default: true,
      required: false,
    },
    variant: {
      type: String,
      default: 'badge',
      validator(v) {
        return ['badge', 'emphasize'].includes(v)
      },
    },
  },
  data: () => ({
    isLoading: false,
    doorStatus: null,
  }),
  computed: {
    getStatusText() {
      if (this.doorStatus === true || this.doorStatus === false) {
        return this.doorStatus ? 'Door Opened' : 'Door Closed'
      } else {
        return this.isDoorOpen ? 'Door Opened' : 'Door Closed'
      }
    },
    getButtonClass() {
      let c = 'vehicle-action-button '
      c += `${this.variant} `

      if (this.doorStatus === true || this.doorStatus === false) {
        c += this.doorStatus ? 'unlocked' : 'locked'
      } else {
        c += this.isDoorOpen ? 'unlocked ' : 'locked'
      }
      console.log('getButtonClass', c)
      return c
    },
    getLockUnlockCommand() {
      if (this.doorStatus === true || this.doorStatus === false) {
        return this.doorStatus
      } else {
        return this.isDoorOpen
      }
    },
  },
  methods: {
    async executeAction(type) {
      if (type === 'edit') {
        this.$emit('edit')
      }

      if (type === 'status') {
        this.isLoading = true
        this.doorStatus = null
        await this.$http
          .patch(
            this.getLockUnlockCommand
              ? SunpodsConfig.api.closeDoor(this.sunpodId)
              : SunpodsConfig.api.openDoor(this.sunpodId)
          )
          .then(() =>
            setTimeout(() => {
              this.$http
                .get(SunpodsConfig.api.doorStatus(this.sunpodId))
                .then((res) => {
                  this.$emit('refresh')
                  this.doorStatus = res.data.is_door_open
                })
                .finally(() => (this.isLoading = false))
            }, 30000)
          )
          .catch((err) => {
            console.log('doorStatusErr', err.response.data)
            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: `Failed[${err.response.status}]`,
                text: err.response.data,
              },
              5000
            )
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-action-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 20px;
  width: 7rem;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
  &:focus {
    outline: none;
  }

  // colors
  &.locked {
    @apply bg-oGreen;
  }
  &.unlocked {
    @apply bg-oPurple;
  }
  &.inactive {
    background-color: #c7c7c7;
  }

  // variants
  &.badge {
    height: 20px;
    border-radius: 3px;
  }

  &.emphasize {
    width: 155px;
    @apply flex items-center justify-between shadow-md rounded-full h-35px;
    @apply px-3 py-0 pl-3 text-center cursor-pointer;

    &.inactive {
      @apply bg-gray-300;
    }
  }
}
@media screen and (max-width: 640px) {
  .badge {
    width: 130px;
  }
}
@media screen and (min-width: 641px) {
  .badge {
    width: 130px;
  }
}
</style>
